import React, { Component } from "react"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import styled from "styled-components"
import HubspotService from "../../services/hubspot-service"

const CTA = styled.div`
  position: sticky;
  top: 100px;
`

class SubscriptionForm extends Component {
  working = false
  success = null
  hbService = null
  state = {
    firstname: "",
    lastname: "",
    email: "",
    company: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    industry: "",
  }
  constructor() {
    super()
    this.hbService = new HubspotService()
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    this.working = true
    this.forceUpdate()
    let contact = this.getContact(this.state)
    this.createContact(contact)
  }
  createContact(contact, company) {
    this.hbService.SubmitForm(contact).then(
      res => {
        this.working = false
        this.success = true
        this.forceUpdate()
      },
      err => {
        this.working = false
        this.success = false
        this.forceUpdate()
      }
    )
  }
  getContact(data) {
    if (!data) return null
    return Object.keys(data).map((key, i) => {
      return {
        name: key,
        value: data[key],
      }
    })
  }
  render() {
    return (
      <CTA className="w-full max-w-sm lg:ml-8">
        <form className="lg:px-8 mt-8 lg:mt-0 pb-8 mb-4" onSubmit={this.handleSubmit}>
          <h3 className="text-blue-500 font-bold lg:text-xl mb-5">
            Get Real-Time Visibility Today
          </h3>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="first-name"
            >
              First Name
            </label>
            <input
              className="appearance-none bg-gray-200 placeholder-gray-600 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="first-name"
              name="firstname"
              type="text"
              placeholder="Jane" 
              required
              value={this.state.firstname}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="last-name"
            >
              Last Name
            </label>
            <input
              className="appearance-none bg-gray-200 placeholder-gray-600 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="last-name"
              name="lastname"
              type="text"
              placeholder="Doe" required
              value={this.state.lastname}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="appearance-none bg-gray-200 placeholder-gray-600 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              name="email"
              type="email"
              placeholder="janedoe@example.com" required
              value={this.state.email}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="company"
            >
              Company
            </label>
            <input
              className="appearance-none bg-gray-200 placeholder-gray-600 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="company"
              name="company"
              type="text"
              placeholder="Example Inc." required
              value={this.state.company}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Get Started
              {this.working === true ? (
                <div className="ml-2 inline-block">
                  <Loader type="Bars" color="#FFFFFF" height={20} width={30} />
                </div>
              ) : (
                <></>
              )}
            </button>
          </div>
          {this.success === true ? (
            <div
              className="text-sm mt-3 bg-green-200 border-t-4 border-green-500 rounded-b text-green-900 px-3 py-1 shadow-md"
              role="alert"
            >
              Thank you for your interest in Archerhub. Looking forward to
              working with you.
            </div>
          ) : (
            <></>
          )}
          {this.success === false ? (
            <div
              className="text-xs mt-3 bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-3 py-1 shadow-md"
              role="alert"
            >
              There was a problem submitting your information. Please try again
              later or contact support.
            </div>
          ) : (
            <></>
          )}
        </form>
      </CTA>
    )
  }
}

export default SubscriptionForm
