import { graphql, Link } from "gatsby"
import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../layout"
import SEO from "../seo"
import SubscriptionForm from "./subscription-form"
import archerhubnews from "../../images/archerhubnews.png"

const Section = styled.section`
  font-size:1.2em;
  a {
    color: #3c80cf;
  }
  p{
    padding-top:15px;
    padding-bottom:15px;
  }
  p:empty {
    margin-top: 30px !important;
    padding:0;
  }
  h2{
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    font-size:1.6em;
  }
`

class NewsPost extends Component {
  render() {
    const post = this.props.data.wordpressPost
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    return (
      <Layout title={siteTitle}>
        <SEO title={post.title} description={post.excerpt} />
        <div className="container mx-auto p-6 mt-12 lg:mt-20">
          <article>
            <header className="flex flex-wrap lg:flex-no-wrap items-center lg:py-8">
              {post.featured_media ? (
                <>
                  {" "}
                  <div className="w-full lg:w-auto lg:flex-shrink">
                    <img
                      className="rounded"
                      width="750"
                      src={post.featured_media.source_url}
                      alt={post.featured_media.title}
                    />
                  </div>
                  <aside className="w-full lg:w-auto lg:pl-5 lg:flex-auto pt-2">
                    <h1 className="font-semibold font-bold lg:font-black text-2xl lg:text-4xl text-gray-800" 
                    dangerouslySetInnerHTML={{__html:post.title}}>
                    </h1>
                  </aside>
                </>
              ) : (
                <>
                  <div
                    className="h-48 bg-cover bg-center rounded"
                    style={{
                      backgroundImage: `url(${archerhubnews})`,
                    }}
                    title="Default Image"
                  />
                  <aside className="w-full lg:w-auto lg:flex-auto pt-2">
                    <h1 className="font-semibold lg:font-bold text-2xl lg:text-3xl text-gray-800">
                      {post.title}
                    </h1>
                  </aside>
                </>
              )}
            </header>
            <div className="flex flex-wrap lg:flex-no-wrap">
              <Section
                className="leading-loose text-gray-800 pt-5 lg:pt-0 lg:w-2/3"
                dangerouslySetInnerHTML={{
                  __html: post.content,
                }}
              />
              <div className="lg:w-1/3">
                <SubscriptionForm />
              </div>
            </div>
          </article>

          <nav className="pt-8">
            <ul className="flex justify-between list-none p-0 underline text-md lg:text-lg text-blue-800 hover:text-blue-500 focus:text-blue-500">
              <li>
                {previous && (
                  <Link to={`/news/${previous.slug}`} rel="prev">
                    <span role="img" aria-labelledby="Previous Post">
                      ⬅️
                    </span>
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={`/news/${next.slug}`} rel="next">
                    <span role="img" aria-labelledby="Next Post">
                      ➡️
                    </span>
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </Layout>
    )
  }
}

export default NewsPost

export const pageQuery = graphql`
  query BlogPostBySlug($id: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: "news" } } } }
    ) {
      edges {
        next {
          slug
          title
        }
        previous {
          slug
          title
        }
      }
    }
    wordpressPost(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      featured_media {
        source_url
        title
      }
    }
  }
`
